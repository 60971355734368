@tailwind base;
@tailwind components;
@tailwind utilities;

*{
 margin: 0px;
 padding: 0px;
}

body {
  margin: 0;
  line-height: 1;
 
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

