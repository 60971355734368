@font-face {
  font-family:montserrat;
  src: url('../public/Montserrat-Medium.ttf');
}

@font-face {
  font-family: archivo_black;
  src: url('../public/ArchivoBlack-Regular.ttf');
}

@font-face {
  font-family:montserrat_bold;
  src: url('../public/Montserrat-Bold.ttf');
}


@font-face {
  font-family:font_thin_large;
  src: url('../public/Kanit-Regular.ttf');
}

.font_thin{
  font-family:montserrat !important;
}

.font_normal{
  font-family:montserrat !important;
}

.font_bold{
  font-family:montserrat_bold;
}

.font_thicker{
  font-family:archivo_black !important;
}

#font_heading{
  font-family:montserrat_bold !important;
  font-size:20px;
  margin: 15px 0px;
}

#font_sub_heading{
  font-family:montserrat_bold !important;
  font-size:14px;
  margin: 15px 0px;
}

#font_description{
  font-family:montserrat !important;
  font-size:14px;
  margin: 10px 0px;
}

#high_lighter:hover{
  color: #e67816;
  font-weight: 800;
}


input[type=date]:required:invalid::-webkit-datetime-edit {
  color: transparent;
}
input[type=date]:focus::-webkit-datetime-edit {
  color: black !important;
}

body {
  font-family:  Arial, Helvetica, sans-serif;
}

body ::-webkit-scrollbar {
    width: 2px !important; /* width of the entire scrollbar */
    scrollbar-width: 10px !important;
    height: 10px;
    padding: 10px;
  }
  
  body ::-webkit-scrollbar-track {
    background: white; /* color of the tracking area */
  }
  
  body ::-webkit-scrollbar-track:hover {
    background-color: rgb(250, 250, 250);
  }
  
  body ::-webkit-scrollbar-thumb {
    background-color: #f0f0f0; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
  }
  
  .ant-tabs-tab.ant-tabs-tab-active {
    border-bottom: 2px solid #158a93 !important;
    z-index: 2;
    /* font-weight: 700 !important; */
    color: black !important;
  }
  
  .ant-tabs-tab.ant-tabs-tab-active {
    color: black !important;
  }
  
  .ant-tabs-tab {
    border-bottom: 1px solid #f0f0f0 !important;
    height: 38px;
  }
  
  .ant-tabs-tab:hover {
    border-bottom: 3px solid #d6d6d6 !important;
    color: black !important;
    /* height: 40px !important; */
  }
  .ant-btn .ant-dropdown-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
  }
  
  .css-1ami7lv-MuiTimelineConnector-root {
    /* color: red; */
    width: 1.2px !important;
    background-color: #e2e2e2 !important;
    flex-grow: 1;
  }
  

  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color:black;
  text-shadow: 0 0 0.25px black;
}

.ant-picker-input input{
  font-size: 13px !important;
  /* padding: '4px 0px' !important; */
}

.ant-picker-input input::placeholder {
  color: #f0f0f0;
  font-size: 11.5px !important;
}

.ant-select-selection-placeholder {
  font-size: 11px !important;
}

.ant-select-selection-item{
  font-size: 13px !important;
  font-weight: 400;
}
.ant-btn-primary{
  background-color: rgb(30 41 59) !important;
  color:white
}
.ant-btn-primary >.span{
  color: white !important;
  
}

.ant-select-selection-search-input {
  font-size: 12px !important;
  /* font-size: 10px !important; */
}

#search_text::placeholder{
  font-size: 11.5px !important;
  color: #ccc;
  padding-left: 3px !important;
}

.ant-btn {
  color: #fff !important;
  cursor: pointer !important;
}

.ant-modal {
  border-radius: 0px !important;
}

.ant-modal-content {
  border-radius: 0px !important;
}

.ant-drawer-body{
  padding:  10px 20px !important;
}


.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.date-input{
  /* opacity: 0; */
}

input[type='date']:not(:focus):in-range::-webkit-datetime-edit-year-field,
  input[type='date']:not(:focus):in-range::-webkit-datetime-edit-month-field,
  input[type='date']:not(:focus):in-range::-webkit-datetime-edit-day-field,
  input[type='date']:not(:focus):in-range::-webkit-datetime-edit-text {
    color: transparent;
  }

/* iOS background fix */
  & > input[type='date']:empty {
    background-color: #f9f9f6;
  }

  /* iOS fix for text alignment */
  & > input::-webkit-date-and-time-value {
    text-align: left;
    color: var(--sg-black);
  }

  /* iOS date-placeholder fix */
  & > input[type='date']:not(:focus):empty:after {
    position: absolute;
    color: #aaa;
    content: attr(placeholder);
  }


 